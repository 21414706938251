import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "принципы-компонентов"
    }}>{`Принципы компонентов`}</h1>
    <h3 {...{
      "id": "кастомизация-через-классы"
    }}>{`Кастомизация через классы`}</h3>
    <p>{`При разработке компонентов мы отдельно реализуем стили в пакете
`}<a parentName="p" {...{
        "href": "https://registry.design-system.hse.ru/-/web/detail/@hse-design/core"
      }}><inlineCode parentName="a">{`@hse-design/core`}</inlineCode></a>{`
с использованием SCSS, следуя методологии `}<a parentName="p" {...{
        "href": "https://ru.bem.info/methodology/"
      }}>{`БЭМ`}</a>{`.
Это позволяет использовать стили даже там, где нет возможности использовать полноценные компонентные фреймворки,
такие как React или Vue. Например, для стилизации страниц готовых систем.`}</p>
    <p>{`Каждый компонент принимает пропы `}<inlineCode parentName="p">{`style`}</inlineCode>{`, `}<inlineCode parentName="p">{`className`}</inlineCode>{` и `}<inlineCode parentName="p">{`classes`}</inlineCode>{`, что позволяет компоновать и перезаписывать стили
на уровне проекта для достижения необходимых результатов. `}<inlineCode parentName="p">{`style`}</inlineCode>{` задает инлайн-стили для корневого элемента,
`}<inlineCode parentName="p">{`className`}</inlineCode>{` задает класс корневому элементу, а `}<inlineCode parentName="p">{`classes`}</inlineCode>{` - классы для внутренних элементов.
При прочих равных следует отдать предпочтение `}<inlineCode parentName="p">{`className`}</inlineCode>{`, а не инлайн-стилям.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`.Page__actionButton {
  margin: 0 auto;
  width: 200px;
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import { Button } from '@hse-design/react';

function Page() {
  return <Button className="Page__actionButton">Click me!</Button>;
}
`}</code></pre>
    <h3 {...{
      "id": "innerref"
    }}>{`innerRef`}</h3>
    <p>{`Большинство компонентов принимают проп `}<inlineCode parentName="p">{`innerRef`}</inlineCode>{`, что позволяет пробросить реф на внутренний DOM-элемент.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import { useRef } from 'react';
import { Button } from '@hse-design/react';

function Page() {
  const ref = useRef<HTMLButtonElement|null>(null);
  return <Button innerRef={ref}>Click me!</Button>;
}
`}</code></pre>
    <h3 {...{
      "id": "проброс-пропов"
    }}>{`Проброс пропов`}</h3>
    <p>{`В каждом компоненте все лишние пропы пробрасываются на корневой или другой подходящий внутренний элемент.
Это позволяет использовать стандартные функции DOM.
На страницах документации компонентов в конце таблицы пропов указано, на какой элемент пробрасываются пропы.`}</p>
    <p>{`Пропы `}<inlineCode parentName="p">{`className`}</inlineCode>{` и `}<inlineCode parentName="p">{`style`}</inlineCode>{` всегда пробрасываются на корневой элемент для удобства стилизации, даже если остальные пропы пробрасываются на более глубокий (например на `}<inlineCode parentName="p">{`input`}</inlineCode>{` в `}<inlineCode parentName="p">{`Input`}</inlineCode>{`).
Для стилизации глубокого элемента в таком случае используйте передачу имени класса через `}<inlineCode parentName="p">{`classes`}</inlineCode>{`, либо соответствующий проп для `}<inlineCode parentName="p">{`style`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import { Button } from '@hse-design/react';

function Page() {
  return <Button onClick={console.log} title="Click for more info">Help</Button>;
}
`}</code></pre>
    <h3 {...{
      "id": "полиморфизм"
    }}>{`Полиморфизм`}</h3>
    <p>{`Некоторые компоненты являются полиморфными и позволяют использовать в качестве корневого элемента произвольный
React-компонент. Такая возможность описана в документации конкретных компонентов.
В таком случае все лишние пропы пробрасываются этому компоненту.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import { Link as RouterLink } from 'react-router';
import { Button } from '@hse-design/react';

function Page() {
  return <Button component={RouterLink} to="/help">Help</Button>;
}
`}</code></pre>
    <p>{`Если есть необходимость использовать полиморфный компонент как корневой для другого полиморфного компонента, то
необходимо обернуть его в конкретный компонент.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import { Button, RichInput, ButtonProps } from '@hse-design/react';

function ButtonA(props: ButtonProps<'a'>) {
  return <Button {...props} component={'a'} />;
}

function Page() {
  return <RichInput
    component={ButtonA}
    label={'Button'}
    href={"https://hse.ru"}
  />;
}
`}</code></pre>
    <h3 {...{
      "id": "перечисления"
    }}>{`Перечисления`}</h3>
    <p>{`Если значение пропа какого-либо компонента задается перечислением (например `}<inlineCode parentName="p">{`variant`}</inlineCode>{` у `}<inlineCode parentName="p">{`Button`}</inlineCode>{`),
то для удобства допустимые значения этого перечисления сохранены в виде объекта в статическое поле (`}<inlineCode parentName="p">{`Button.Variant`}</inlineCode>{`):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import { Button } from '@hse-design/react';

function Page() {
  return <Button variant={Button.Variant.primary}>Ok</Button>;
}
`}</code></pre>
    <h3 {...{
      "id": "слоты"
    }}>{`Слоты`}</h3>
    <p>{`Некоторые компоненты имеют пропы-слоты. Такие пропы обозначаются типом `}<inlineCode parentName="p">{`Slot<Props>`}</inlineCode>{`.
Это означает, что проп может принять либо JSX элемент, либо рендер-функцию, в аргументы которой передаётся объект типа `}<inlineCode parentName="p">{`Props`}</inlineCode>{`.
Тип `}<inlineCode parentName="p">{`Props`}</inlineCode>{` зависит от конкретного пропа конкретного компонента и описан в таблице пропов на странице документации этого компонента.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import { Alert, AlertLink } from '@hse-design/react';

// Передача JSX элемента в слот
function Component1({ onClose }) {
  return <Alert
    actions={
      <AlertLink onClick={onClose}>Закрыть</AlertLink>
    }
    onClose={onClose}
  />
}

// Передача рендер-функции в слот
function Component2({ onClose }) {
  return <Alert
    actions={({ close }) => (
      <AlertLink onClick={close}>Закрыть</AlertLink>
    )}
    onClose={onClose}
  />
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      